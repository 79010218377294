@import '/src/assets/scss/commonV2.scss';
@import "/src/components/button/Buttons.module.scss";


.passwd_err_help_block {
  margin-bottom: 4px;

  .err_list_item {
    padding: 2px 16px;
  }

  .err_text {
    margin-left: 5px;
  }
}

.terms {
  display: flex;
  flex-direction: row;
}