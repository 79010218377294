@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.checkbox_text {
  word-break: break-all;
}

.select_block {
  // background: #f00;
  
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 8px;
}

.search_block {
  // background: #f00;
  
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.search_bar {
  // background: #0f0;
  
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  
  gap: 8px;
}