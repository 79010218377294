@use '/src/assets/scss/indexV2.scss';

.table_container{
  border-radius: 4px;
  border: 2px solid var(--green-alpha-alpha-20);
}

// ----table header----
.table_header{
  border: 0;
  height: 50px;
  // background-color:var(--green-alpha-alpha-20);
  }
.header_cell{
  background-color:var(--green-alpha-alpha-20);
  border-right: 2px solid var(--green-alpha-alpha-20);
}

// ----table body----
.table_body{
  border: 0;
}

.body_row_interactive {
  color: var(--white-white-75);
  // cursor: pointer;
  // -webkit-cursor: pointer;
  // border: 10px solid #1e88e5;
    &:hover {
        background-color: var(--green-alpha-alpha-8);
        // cursor: pointer;
        td,th {
          color: var(--white-white);
          // font-weight: 600;
          border-bottom: 1px solid var(--green-alpha-alpha-16);
        }
      }
}

.body_row_peptide_list {
  color: var(--white-white-75);
  cursor: pointer;
  -webkit-cursor: pointer;
  // border: 10px solid #1e88e5;
  &:hover {
    background-color: var(--green-alpha-alpha-8);
    // cursor: pointer;
    td,th {
      // color: #880 !important;
      color: var(--white-white);
      border-bottom: 1px solid var(--green-alpha-alpha-16);
    }
  }
}

.body_cell_textLink{
    color: var(--green-alpha-alpha-75);
    text-decoration: none; 
  
    &:hover {
      cursor: pointer;
    }
}

//---- else ----
.table_borderless{
  border: none;
}