@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/commonV2.scss';
@import '/src/assets/scss/fonts.css';

//------------stop and delete dialog---------------
.dialog {
  :global(.MuiDialog-paper) {
    background-color: var(--black-black-90); 
    border: 1px solid var(--green-alpha-alpha-75);
  }

  .dialog_title {
    padding-top: 20px;
    color: var(--white-white-90);
    @extend .Title-Audiowide20px-Regular-Title;

  }

  .dialog_content {
    padding: 20px 24px;
    color: var(--white-white-80);
    min-width: 300px;

    :global(.MuiDialogContentText-root) {
      color: var(--white-white-80);
      @extend .Contents-QuanticoBody20px-Bold-White_90;

    }
  }

  .dialog_actions {
    padding: 15px 24px;
    display: flex;
    gap: 10px;
  }
}

//------------rename dialog---------------
.dialog_fixed {
  :global(.MuiDialog-paper) {
    background-color: var(--black-black-90); 
    border: 1px solid var(--green-alpha-alpha-75);
    width: 500px;
    min-height: 250px;
  }

  .dialog_title {
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--white-white-90);
    @extend .Title-Audiowide20px-Regular-Title;

  }

  .dialog_content {
    padding: 20px 24px;
    color: var(--white-white-80);
    min-width: 300px;

    :global(.MuiDialogContentText-root) {
      color: var(--white-white-80);
      @extend .Contents-QuanticoBody20px-Bold-White_90;

    }
  }

  .dialog_actions {
    padding: 15px 24px;
    display: flex;
    gap: 10px;
  }
}