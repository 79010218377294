@import '/src/assets/scss/commonV2.scss';

// Contents
.layout {
  // background-color: #00f;  
  box-sizing: border-box;
  display: flex;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  // width: Fill (1,440px)px;
  // height: Hug (1,311px)px;
  padding: 8px 20px 40px 20px;
  gap: 40px;
  
  
  // width: 1440px;
}
