@use '/src/assets/scss/variablesV2' as v2;
// @import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

// Section
.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  
  // width: Fill (1,440px)px;
  // height: Hug (283px)px;
  padding: 80px 0px 80px 0px;
  gap: 40px;
  
  
  height: 283px;
  
  background: radial-gradient(34.04% 58.43% at 50% 100%, rgba(100, 255, 218, 0.3) 0%, rgba(3, 9, 9, 0) 100%);
}
/*
.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  // width: Hug (140px)px;
  // height: Hug (45px)px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border: 1px 0px 0px 0px;
  

  height: 45px;
  
  // &:hover {
    // cursor: pointer;
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Menu-item-Borders;
  // }
  &:active {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
    @extend .Menu-item-Borders;
  }
}
*/