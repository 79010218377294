@use './variables' as v;

/* 
fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
  ].join(","),
 */
 
@mixin font {
  font-family: v.$fontPTSans;
}

//---------------------------------------------------------------------------
//  Font
//---------------------------------------------------------------------------
/* Display 1 */
@mixin display1 {
  // figma - 03 Peptides - 12%
  /* Display 1 */
  // font-family: Myriad Pro;
  // font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 105.6px */
  
  @include font;
  font-size: 90px;
}

//---------------------------------------------------------------------------
/* Heading 1 */
@mixin heading1 {
  // figma - 01 Homepage - Comprehensive database of food-derived bioactive peptides
  /* Heading/H1 */
  // font-family: Myriad Pro;
  // font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  
  @include font;
  font-size: 46px;
}
/* Heading 2 */
@mixin heading2 {
  // figma - 01 Homepage - Data Retrieval
  /* Heading/H2 */
  // font-family: Myriad Pro;
  // font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  
  @include font;
  font-size: 38px;
}
/* Heading 3 */
@mixin heading3 {
  // figma - 01 Homepage - Get any questions? We are here to help.
  /* Heading/H3 */
  // font-family: Myriad Pro;
  // font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  
  @include font;
  font-size: 30px;
}
/* Heading/H4 */
@mixin heading4 {
  // figma - 03 Peptides - #6
  /* Heading/H4 */
  // font-family: Myriad Pro;
  // font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */

  @include font;
  font-size: 22px;
}

//---------------------------------------------------------------------------
/* Body 1 */
@mixin body1 {
  // figma - 01 Homepage - Purpose of the Database
  /* Body/B1 */
  // font-family: Myriad Pro;
  // font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  
  @include font;
  font-size: 16px;
}

/* Body 1 - Strong */
@mixin body1strong {
  // figma - 02 Proteins - Bovine Milk
  /* Body/B1 - Strong */
  // font-family: Myriad Pro;
  // font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */

  @include font;
  font-size: 16px;
}

/* Body 2 */
@mixin body2 {
  // figma - 00 Nav/Footer - © 2023 Amway. All Rights Reserved.
  /* Body/B2 */
  // font-family: Myriad Pro;
  // font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
  
  @include font;
  font-size: 14px;
}

/* Body 2 - Strong */
@mixin body2strong {
  // figma - 00 Nav/Footer - Protein
  /* Body/B2 - Strong */
  // font-family: Myriad Pro;
  // font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
  
  @include font;
  font-size: 14px;
}

/* Body/B3 */
@mixin body3 {
  /* Body/B3 */
  // font-family: Myriad Pro;
  // font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
  
  @include font;
  font-size: 12px;
}

/* Size 20 - Strong */
@mixin size20strong {
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */

  @include font;
  font-size: 20px;
}

@mixin size18-grey100 {
  /* Body/B3 */
  // font-family: Myriad Pro;
  // font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
  color: v.$grey100;

  @include font;
  font-size: 18px;
}
//---------------------------------------------------------------------------
// Font + Color
//---------------------------------------------------------------------------
/* Display 1 */
@mixin display1-darkGreen {
  @include display1;
  
  // color: var(--dark-green, #073431);
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Body 1 */
@mixin body1-grey50 {
  @include body1;
  
  color: v.$grey50;
}

@mixin body1-grey100 {
  @include body1;

  /* Neutral/Grey 100 - Body */
  // color: #6F6F6F;
  color: v.$grey100;
}

@mixin body1-darkGreen {
  @include body1;
  
  // color: var(--dark-green, #073431);
  color: v.$darkGreen;
}

@mixin body1-amwayBlack {
  @include body1;

  /* Neutral/Amway Black - Heading */
  // color: #2C2C2C;
  color: v.$amwayBlack;
}

@mixin body1-white {
  @include body1;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

@mixin body1-red {
  @include body1;
  
  // color: var(--semantic-red, #FF3A00);
  color: v.$red;
}

@mixin body1-green4 {
  @include body1;
  
  // color: var(--green, #1A8074);
  // font-feature-settings: 'clig' off, 'liga' off;
  color: v.$green4;
}
//---------------------------------------------------------------------------
@mixin body1strong-amwayBlack {
  @include body1strong;

  /* Neutral/Amway Black - Heading */
  // color: #2C2C2C;
  color: v.$amwayBlack;
}

@mixin body1strong-darkGreen {
  @include body1strong;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

@mixin body1strong-grey100 {
  @include body1strong;
  
  color: v.$grey100;
}

@mixin body1strong-white {
  @include body1strong;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

@mixin body1strong-green4 {
  @include body1strong;
  
  // color: var(--green, #1A8074);
  // font-feature-settings: 'clig' off, 'liga' off;
  color: v.$green4;
}
//---------------------------------------------------------------------------
/* Body 2 */
@mixin body2-white {
  @include body2;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

@mixin body2-amwayBlack {
  @include body2;

  /* Neutral/Amway Black - Heading */
  // color: #2C2C2C;
  color: v.$amwayBlack;
}

@mixin body2-darkGreen {
  @include body2;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

@mixin body2-grey100 {
  @include body2;

  /* Neutral/Grey 100 - Body */
  // color: #6F6F6F;
  color: v.$grey100;
}

@mixin body2-white05 {
  @include body2;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;

  opacity: 0.5;
}

@mixin body2-white {
  @include body2;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

//---------------------------------------------------------------------------
/* Body 2 - Strong */
@mixin body2strong-white {
  @include body2strong;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

@mixin body2strong-darkGreen {
  @include body2strong;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
@mixin body3-grey100 {
  @include body3;
  
  // color: var(--neutral-grey-100-body, #6F6F6F);
  color: v.$grey100;
}

//---------------------------------------------------------------------------
/* Heading 1 */
//---------------------------------------------------------------------------
@mixin heading1-darkGreen {
  @include heading1;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Heading 2 */
//---------------------------------------------------------------------------
@mixin heading2-darkGreen {
  @include heading2;
  
  // color: var(--dark-green, #073431);
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Heading 3 */
//---------------------------------------------------------------------------
@mixin heading3-darkGreen {
  @include heading3;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
//styleName: Heading/H4;
//---------------------------------------------------------------------------
@mixin heading4-white {
  @include heading4;

  // background: #FFFFFF;
  color: v.$white;
}

@mixin heading4-darkGreen {
  @include heading4;
  
  color: v.$darkGreen;
}

@mixin heading4-green75 {
  @include heading4;
  
  color: v.$grey75;
}


//---------------------------------------------------------------------------
@mixin size20strong-darkGreen {
  @include size20strong;
  
  color: v.$darkGreen;
}

@mixin size20strong-grey100 {
  @include size20strong;
  
  color: v.$grey100;
}

@mixin size20strong-white {
  @include size20strong;
  
  color: v.$white;
}