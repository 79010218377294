@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

.layout {
  // background-color: #00f !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: content-box;
  
  isolation: isolate;
}

//---------------------------------------------------------------------------
// BG
.bg {
  // background-color: #00f;
  width: 1440px;
  height: 786px;
  gap: 0px;
  

  position: absolute;
  top: 0px;

  // background-image: url('/assets/images/01Homepage/BG.png');
  // background-size: cover;
  // background-position: 0px -104px;
  // background-repeat: no-repeat;
  
  z-index: -1;
}

// Contents
.frame {
  // background-color: #f00;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  
  // position: absolute;
  // top: 0px;
  
  // width: 1440px;
  // height: 786px;
  // padding: 186px 300px 80px 300px;
  gap: 40px;
  
  
  width: 100%;
  height: 786px - 80; //height - Header
}

//---------------------------------------------------------------------------
// Title
.frame_text {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  // width: Fixed (800px)px;
  // height: Hug (176px)px;
  // top: 186px;
  // left: 320px;
  gap: 12px;
  
  
  width: 1000px;
  // height: 176px;
  
  // margin-top: 186px;
  margin-top: 186px - 80px; //top - Header
}

//---------------------------------------------------------------------------
// Search
.frame_search {
  // background-color: #f0f;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: content-box;
  
  // width: 642px;
  // height: 74px;
  // top: 402px;
  // left: 400px;
  gap: 0px;
  
}

// Search Bar
.search_input {
  // background-color: #0f0 !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: content-box;
  
  // width: 642px;
  // height: 42px;
  // top: 402px;
  // left: 400px;
  gap: 0px;
  
  
  // height: 42px;
  height: 48px;
  background: var(--black-black); //rgba(3, 9, 9, 1)
  
  // Down Shadow/shadow down 1
  box-shadow:
    0px 2.77px 2.21px 0px rgba(100, 255, 218, 0.0197),
    0px 6.65px 5.32px 0px rgba(100, 255, 218, 0.0283),
    0px 12.52px 10.02px 0px rgba(100, 255, 218, 0.035),
    0px 22.34px 17.87px 0px rgba(100, 255, 218, 0.0417),
    0px 41.78px 33.42px 0px rgba(100, 255, 218, 0.0503),
    0px 100px 80px 0px rgba(100, 255, 218, 0.07),
    0px 2px 0px 0px rgba(100, 255, 218, 1) inset;

  backdrop-filter: blur(24px);
  
  // Contents
  .input_center {
    // background: #00f !important;
    display: flex;
    flex-direction: row;
    box-sizing: content-box;
    // justify-content: center;
    // justify-items: center;
    
    // width: Hug (640px)px;
    // height: Hug (40px)px;
    top: 1px;
    left: 1px;
    gap: 0px;
    
    // width: 640px;
    // height: 40px;
    
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    
    .tabs {
      // min-height: 40px;
      // height: 40px;
      min-height: 48px;
      height: 48px;
      
      &.MuiTab-root {
        // min-height: 40px;
        // height: 40px;
        min-height: 48px;
        height: 48px;
      }
    }
    
    .tab_left,
    .tab_right {
      // background: #0f0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      
      // width: Hug (87px)px;
      // height: Fixed (40px)px;
      padding: 0px 16px 0px 16px;
      gap: 0px;
      border: 1px 0px 0px 0px;

      width: 87px;
      // height: 40px;
      
      &:hover {
        // background-color: #0f0 !important;
        background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
        @extend .Menu-item-Borders;
      }
      &:active {
        // background-color: #00f !important;
        background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
        @extend .Menu-item-Borders;
      }
      
      // reactjs - How to target .Mui-selected state class using CSS modules - Stack Overflow
      // https://stackoverflow.com/questions/78720445/how-to-target-mui-selected-state-class-using-css-modules
      &:global(.MuiButtonBase-root) {
        color: var(--white-white-50);
        //styleName: Contents: Quantico/Body/16px: Regular;
        @extend .Contents-QuanticoBody16px-Regular;
      }
      &:global(.Mui-selected) {
        // background: #0ff !important;
        
        color: var(--white-white);
        //styleName: Contents: Quantico/Body/16px: SemiBold;
        // @extend .Contents-QuanticoBody16px-SemiBold;
        font-weight: bold;
        line-height: 1.8;
        
        background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
        @extend .Menu-item-Borders;
      }
    }
    
    // Dropdown/Search
    .center_type {
      // background: #ff0;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: center;
      justify-items: center;
      
      // width: Fixed (140px)px;
      // height: Fixed (40px)px;
      padding: 0px 4px 0px 10px;
      gap: 16px;
      // border: 0px 1px 0px 1px;
      
      // width: 140px;
      // height: 40px;
      
      width: 140px + 30;
      // height: 48px;
      
      // border-width: 0px, 1px, 0px, 1px;
      // border-style: solid; //NOT work
      border: 1px solid;
      // Gradient/Stroke 1
      @include GradientStroke1;
    }
    
    .search_text {
      //styleName: Contents: Quantico/Body/16px: Regular;
      @extend .Contents-QuanticoBody16px-Regular-White_50;
    }
    
    // Search Bar
    .center_word {
      // background: #f0f;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      // align-content: center;
      
      // width: Fixed (323px)px;
      // height: Fixed (40px)px;
      padding: 0px 8px 0px 8px;
      gap: 0px;
      
      // width: 323px;
      // height: 40px;
      
      width: 323px - 30;
      height: 48px;
    }
    
    // search
    .button_search {
      // background: #00f;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      width: 24px;
      height: 24px;
      padding: 3.26px 0px 0px 0px;
      gap: 0px;
      
      
      &:global(.MuiIconButton-root) {
        // White/White
        color: var(--white-white);
      }
      &:global(.MuiIconButton-root.Mui-disabled) {
        // color: #f00 !important;
        // Green Alpha/Alpha_40
        color: var(--green-alpha-alpha-40);
      }
    }
  }
}

//---------------------------------------------------------------------------
// Multifunctional Peptide
.search_option {
  // background-color: #f00;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  // align-items: flex-start;
  // align-items: center;
  
  // line_6 from Navbar
  .text_line {
    margin-top: 10px;
    
    // width: 22px;
    // height: 0px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    
    // angle: -90 deg;
    width: 0px;
    height: 22px;
    
    border: 1px solid rgba(100, 255, 218, 0.3);
  }
}

.option_multi {
  // background-color: #ff0;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  
  padding: 8px 8px 6px 8px;
  
  // width: 165px;
  height: 24px;
}

// Advanced Search
// .option_advanced {
  // background-color: #0ff;
// }


.option_milk {
  // background-color: #ff0;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  
  // padding: 8px 8px 6px 8px;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 6px;
  padding-left: 8px;
  
  // width: 30px;
  height: 24px;
}

.option_plant {
  // background-color: #f0f;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  
  // border-radius: 0px;
  // padding: 8px 8px 6px 8px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 6px;
  padding-left: 12px;
  
  // width: 30px;
  height: 24px;
}
