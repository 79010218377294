@import '/src/assets/scss/commonV2.scss';
/*
// Contents
.layout {
  move to src\layouts\search\SearchResultLayout.module.scss
}
*/
//---------------------------------------------------------------------------
// Frame 29733
.frame_search {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 1200px;
  height: 40px;
  color: var(--white-white-75);
  /* Dropdown */

  // box-sizing: border-box;
  
  // /* Auto layout */
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // padding: 0px 12px 0px 16px;
  // gap: 16px;
  
  // margin: 0 auto;
  // width: 800px;
  // height: 50px;
  
  // /* Green Alpha/Alpha_16 */
  // border: 1px solid rgba(100, 255, 218, 0.16);
  // border-radius: 4px;
  
  /* Inside auto layout */
  // flex: none;
  // order: 0;
  // flex-grow: 0;
  


//-----------------------------

  // 01-lable-capsule
  .search_text {
  
      box-sizing: border-box;
      display: flex;
      height: 50px;
      width: 800px;
      padding: 0px 12px 0px 16px;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      border: 2px solid rgba(100, 255, 218, 0.16);
  
      &:hover {
        border: 2px solid rgba(100, 255, 218, 0.5);
        background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      }
    }
  
  
  
    // Frame 29732
    .search_index {
      // background: #00f;
  
      display: flex;
      height: 50px;
      width: 180px;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
    }
  // Dropdown
  /*.search_menu { //move to jsx
    display: inline-flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    
    border-radius: 8px;
    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    // background: #FFF;
    border: 1px solid v.$grey25;
    background: v.$white;

    // Card - Drop Shadow
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
  }*/
}

//---------------------------------------------------------------------------
// 03-table
.frame_table {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: flex-start;

  // border-radius: 4px;
  // border: 1px solid var(--green-alpha-alpha-16);
  // background: var(--black-black);
}

.fixed_table {
    // background: rgb(255, 0, 230);

  width: 1200px;
  min-height: 550px;
}

//---------------------------------------------------------------------------

.frame_bottom {
  // background: #f00;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .bottom_bar {
    // background: #f00;
    display: flex;
    flex-direction: row;
    gap: 0px;
  }

  .menu_button {
    // background: rgb(107, 180, 207);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 0px 16px;
    cursor: pointer;
    border: 2px solid transparent; 

    &_selected {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      @extend .Menu-item-Borders;
    }

    &:hover:not(&_selected) {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      @extend .Menu-item-Borders;
    }

    &:active:not(&_selected) {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
      @extend .Menu-item-Borders;
    }
}
// .frame_bottom {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 16px;

//   .bottom_bar {
//     display: flex;
//     flex-direction: row;
//     gap: 0px;
//     // 添加固定寬度，根據你的按鈕數量調整
//     min-width: 400px; // 根據實際需要調整
//     justify-content: center; // 居中對齊按鈕
//   }

//   .menu_button {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 45px;
//     min-width: 15px; // 為數字按鈕設置最小寬度
//     padding: 0px 16px;
//     cursor: pointer;
//     border: 2px solid transparent;

//     // 特殊按鈕（Previous/Next）的寬度
//     &[data-type="nav"] {
//       min-width: 100px; // 為 Previous/Next 按鈕設置更大的寬度
//     }

//     &_selected {
//       background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
//       @extend .Menu-item-Borders;
//     }

//     &:hover:not(&_selected) {
//       background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
//       @extend .Menu-item-Borders;
//     }

//     &:active:not(&_selected) {
//       background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
//       @extend .Menu-item-Borders;
//     }
//   }


  .divider {
    height: 80px;
    width: 1px;
    background: rgba(255, 255, 255, 0.12);
  }

  .go_to_page_block {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0px 16px;
  }

  .go_to_page_input {
    width: 60px;
    background: transparent;
    color: var(--green-green-normal, #64FFDA);
    text-align: center;
    font-family: 'Quantico';
    font-size: 16px;
    border: 1px solid rgba(100, 255, 218, 0.16);
    padding: 4px;

    &:hover {
      border-color: rgba(100, 255, 218, 0.32);
    }

    &:focus {
      outline: none;
      border-color: rgba(100, 255, 218, 0.5);
    }

    &::placeholder {
      color: rgba(100, 255, 218, 0.6);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .go_to_btn {
    @extend .menu_button;
  }
}

.row_select {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}