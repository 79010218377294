@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;

// $paddingX: 200;
$paddingX: 130;
$paddingX_Label: -20;

.venn2_img,
.venn3_img {
  // background: #f00;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  position: relative;
  background-repeat: no-repeat, no-repeat;
  background-position: center; 
  
}
.venn2_img {
  // background-image: url("../../assets/img/ToolCRS/venn2.png"); //move jsx
  height: 500px;
}
.venn3_img {
  // background-image: url("../../assets/img/ToolCRS/venn3.png"); //move jsx
  height: 650px;
}

//---------------------------------------------------------------------------
@mixin hover_text {
  &:hover {
    color: #64ffda;
    cursor: pointer;
  }
}

.venn3_label_a,
.venn3_label_b,
.venn3_label_c,
.venn3_label_ab,
.venn3_label_ac,
.venn3_label_bc,
.venn3_label_abc,
.venn2_label_left,
.venn2_label_center,
.venn2_label_right {
  // background: #00f;
  
  position: absolute;
  @include hover_text;
}
.venn2_label_left {
  top: 140px;
  left: 160px;
}
.venn2_label_center {
  top: 50px;
  left: 620px;
}
.venn2_label_right {
  top: 140px;
  left: 800px;
}

.venn2_number_left,
.venn2_number_center,
.venn2_number_right {
  // background: #0f0;
  
  // position: absolute;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 200px;
  
  top: 70px;
  padding-top: 80px;
  padding-bottom: 80px;  
  
  @include hover_text;
}
.venn2_number_left {
  // background: #ff0;
}
.venn2_number_center {
  // background: #f0f;
}
.venn2_number_right {
  // background: #0ff;
}

//---------------------------------------------------------------------------

.venn3_label_a {
  top: 20px;
  left: 340px + $paddingX_Label;
}
.venn3_label_b {
  top: 260px;
  left: 150px + $paddingX_Label;
}
.venn3_label_c {
  top: 260px;
  left: 820px + $paddingX_Label;
}
.venn3_label_ab {
  top: 100px;
  left: 150px + $paddingX_Label;
}
.venn3_label_ac {
  top: 110px;
  left: 750px + $paddingX_Label;
}
.venn3_label_bc {
  // background: #0f0;
  top: 570px;
  left: 0px + $paddingX_Label;
  
  width: 480px;
}
.venn3_label_abc {
  top: 570px;
  left: 680px + $paddingX_Label;
}

.venn3_number_a,
.venn3_number_b,
.venn3_number_c,
.venn3_number_ab,
.venn3_number_ac,
.venn3_number_bc,
.venn3_number_abc {
  // background: #444;
  
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  
  padding-top: 35px;
  padding-bottom: 35px;
  
  @include hover_text;
}

.venn3_number_a {
  // background: #f00;
  
  width: 250px;
  top: 165px;
  // left: 485px;
}
.venn3_number_b {
  // background: #0f0;
  
  width: 150px;
  top: 350px;
  left: 485px - 30px - $paddingX; //320px;
}
.venn3_number_c {
  // background: #00f;
  
  width: 150px;
  top: 350px;
  left: 485px + $paddingX; //650px;
}
.venn3_number_ab {
  // background: #ff0;
  
  width: 100px;
  top: 260px;
  left: 485px - 60 - (100/2); //400px;
}
.venn3_number_ac {
  // background: #f0f;
  
  width: 100px;
  top: 260px;
  left: 485px + 60 + (100/2); //620px;
}
.venn3_number_bc {
  // background: #0ff;
  
  width: 150px;
  top: 370px;
  // left: 485px;
}
.venn3_number_abc {
  // background: #ccc;
  
  width: 100px;
  // left: 485px;
}