// @use '/src/assets/scss/variables' as v;
// @use '/src/assets/scss/variablesV2';
@import '/src/assets/scss/font';

$leftPercentage: 30%;
$rightPercentage: 100%-$leftPercentage;

.layout {
  // background-color: #00f;
  margin-top: #{72 + 58 + 24px}; //03-section-header height
  padding-top: 24px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 120px;
  padding-right: 120px;
  justify-content: center;

  width: 1440px;
  box-sizing: border-box;
}

//---------------------------------------------------------------------------
.each_row{
  &:hover{
    background-color: var(--green-alpha-alpha-8);
    border-bottom: 1px solid var(--green-alpha-alpha-16);
    
    td, th {
      color: var(--white-white);
    }
  }
}

.peptide_name_link {
  text-decoration: underline;
  color:var(--green-alpha-alpha-75) !important;
  &:hover {
    cursor: pointer;
  }
}