// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

// from /* 03-cta */
.layout {
    // background-color: #00f;
  /* 03-cta */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0px 120px 64px 120px;
  padding-top: 64px;
  // padding-left: 120px;
  padding-bottom: 64px;
  // padding-right: 120px;
  justify-content: center;
  
  width: 1440px;
  height: 200px;
  box-sizing: border-box;
}

//---------------------------------------------------------------------------
.frame {
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;

  width: 1200px;
  height: 136px;

  // background: #0DDDCD;
  background: v.$green;
}