

.sidebar_container {
  position: relative;
  width: 240px;
  min-height: 800px;
}

.sidebar_background {
  // background: #ff0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.sidebar_content {
  // background: #f0f;
  position: relative;
  z-index: 1;
  padding: 0px 12px 0px 12px;
  height: 100%;
  box-sizing: border-box;
}
.svg_style{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  transform: 'translateX(0)'
}