// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

@mixin column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.layout {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 128px 120px;
    justify-content: center;

    width: 100%;
    box-sizing: border-box;
    
    
    // Gradient/BG Green 3
    background: url( map-get($imgs, 'Gradient/BG Green 3') );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .main_block {
      width: 400px;
      // background-color: v.$grey25;
      // border: 0.5px solid v.$grey50;
      
      gap: 24px;

      @include column-center;
    }
    
    .button {
      // background: #0f0;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      // width: Hug (400px)px;
      // height: Hug (108px)px;
      gap: 8px;
      
      width: 400px;
    }
}