// @use '/src/assets/scss/variablesV2' as v2;
@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/commonV2.scss';

//-----------------------------------
.layout {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease;
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  z-index: 10;
  width: 60px;
  height: 60px;
  padding: 0; 

  &:hover {
    svg {
      color: #64ffda !important;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}


$ratio: ((1224-120)/1440);
@function scale($number) {
  @return calc($number * $ratio);
}
@function scaleT($number) {
  @return calc(($number * 1.2 * $ratio) - 100px);
}
@function scaleL($number) {
  @return calc(($number * 1.1 * $ratio));
}

.center {
  position: absolute;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease;
}

.bgFirst {   
  background-image: url($pathAboutUs + 'NewTimeline BG 2023.png');
  left: 0; 
}  

.bgSecond {   
  background-image: url($pathAboutUs + 'NewTimeline BG 2024.png');
  left: 100%; 
}

// slide animation
@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.fg {
  position: relative;
  width: 100%;
  height: 670px;
  max-width: 1104px;
  margin: 0 auto;
  transition: opacity 0.3s ease;
}

  .area {
    position: absolute;
    // transition: 0.5s ease;
    // -webkit-transition: 0.5s ease;
  }
  

  //------------  2023 Cards/Timelines position  ------------
  .area31 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(390px);
    left: scaleL(200px);
  }

  .area32 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(200px);
    left: scaleL(343px);
  }

  .area33 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(420px);
    left: scaleL(586px);
  }

  .area34 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(560px);
    left: scaleL(867px);
  }

  .area35 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(215px);
    left: scaleL(964px);
  }

  .area36 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(388px);
    left: scaleL(1114px);
  }


  //------------ 2024 Cards/Timelines position ------------
  .area41 {
    // background: #ff0 !important;
    @extend .area;
    
    // width: Hug (346px)px;
    // height: Hug (83.49px)px;
    top: scale(490px);
    left: scale(445px);

    // &:hover {
    //   width: 427px;
    //   height: 255px;
    // }
  }
  .area42 {
    // background: #f0f !important;
    @extend .area;
    
    // width: Hug (328px)px;
    // height: Hug (83.49px)px;
    top: scale(260px);
    left: scale(387px);
    
  }
  .area43 {
    @extend .area;
    // width: Hug (183px)px;
    // height: Hug (83.49px)px;
    top: scale(55px);
    left: scale(561px);
    
  }
  .area44 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(555px);
    left: scaleL(808px);
    
  }
  .area45 {
    @extend .area;
    // width: Hug (211px)px;
    // height: Hug (83.49px)px;
    top: scaleT(160px);
    left: scaleL(930px);
    
  }
  .area46 {
    @extend .area;
    // width: Hug (116px)px;
    // height: Hug (83.49px)px;
    top: scaleT(385px);
    left: scaleL(1046px);
  }

