@import '/src/assets/scss/indexV2.scss';

.row_select {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}

.select_each_block {
  display: flex;
  gap: 10px;
}

.select_class {
  width: 200px;
  margin-right: 8px;
}

.select_source {
  width: 340px;
  // margin-right: 8px;
}

.select_protein {
  width: 615px;
}
.selectItem_button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  gap: 8px;

  .button_checked {
    box-sizing: border-box;
    
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    border-radius: 100px;
    // border: 1px solid var(--Dark-Green, #073431);
    border: 1px solid var(--green-alpha-alpha-8);
    
    color:var(--green-alpha-alpha-8);
    background-color: var(--white-white);
    // @include body2strong-darkGreen;

    &:hover {
      background-color: var(--green-alpha-alpha-8);
      // background-color: v.$green;
    }
  }
}

.third_textfield {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.row_button {
  margin: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}