@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/fonts_colorsV2.scss';
@import '/src/assets/scss/variablesV2';
// @use '/src/assets/scss/font' as font;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';
// @import '/src/assets/scss/variables';

$border-radius: 4px;

.table_row {
  &:hover {
    @include v.blockHoverStyle;
  }
}

//Top Frame
.feature_select {
  display: flex;
  align-items: center;
}

.feature_btn {
  margin: 0 10px;
  width: 80px;
  height: 48px;
  color: black;
  /* Green */
  //background: #0DDDCD;
  background: v.$green;
  border-radius: 5px;
}

.select_title {
  margin-right: 50px;
}

//------------------------------------------------------------------------------
// File upload
.fields_block {
  // background: #f00;
  display: flex;
  flex-direction: row;
  // gap: 8px;
}

// Please select descriptor
.select_descriptor {
  width: 200px;
  margin-right: 8px;
}

// Download
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.link {
  // color: #1e88e5;
  // color: v.$green;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

//------------------------------------------------------------------------------
// FASGAI...VSW
.table_title{
  text-decoration: 'underline';
  @extend .Title-Audiowide20px-Regular-White;
}