@import '/src/assets/scss/commonV2.scss';

.gradient_card {
  position: relative;
  cursor: pointer;
  filter: brightness(0.85); /* 暗 */
  transition: all 0.3s ease;
  
  // Gradient/Stroke 2
  @extend .Border-Gradient-Stroke1;
  
  &:hover {
    filter: brightness(1); /* 亮 */
    
    box-shadow: 
      40px 80px 120px 0px rgba(100, 255, 218, 0.3),
      0px 4px 32px 0px rgba(100, 255, 218, 0.6);
    // box-shadow: 
    //   40px 80px 120px 0px rgba(100, 255, 218, 0.3),
    //   0px 4px 32px 0px rgba(100, 255, 218, 0.6),
    //   inset 40px 80px 120px 0px rgba(100, 255, 218, 0.3);
      
    border: 2px solid;
    // Gradient/Stroke 2
    @include GradientStroke2;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%),
    // linear-gradient(0deg, rgba(100, 255, 218, 0.16), rgba(100, 255, 218, 0.16));
  }
}

.main {
  // background: #f00;
  padding: 16px 16px 16px 16px !important;
}
