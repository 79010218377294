@use '/src/assets/scss/commonV2.scss';
@import '/src/assets/scss/variablesV2';

.main {
  // background: #f00;
  display: flex;
  flex-direction: column;
  
  width: 100%;

  .section_title {
    @extend .Title-Audiowide20px-Regular-White
  }

  .section_sub_title {
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--white-white-50);
    // color: red;

  }

  .chart_block {
    border: 2px solid var(--green-alpha-alpha-8);
    border-radius: 8px;
    padding: 30px 40px;
    background: url(map-get($imgs,'Gradient/BG 2 Hover')) no-repeat;
    background-size: 100% auto; 

    .chart_block_title_row {
      // background: #f00;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .chart_block_title {
        // background-color: #0f0;
        // @extend .Title-Audiowide16px-Regular-White;
        // @extend .Title-Audiowide16px-Regular-White_75;
        @extend .Title-Audiowide20px-Regular-White;
        
        // white-space: pre-line;
        // overflow-wrap: break-word;
        // word-break: break-all;
      }

      .chart_block_sub_title {
        // background-color: #00f;
        color: var(--white-white-75);
        text-align: right;

        .number {
          color: var(--white-white);
          font-weight: 600;
        }
      }
    }
  }
  
  // .main_chart {}
  
  .main_stacked_bar_chart {
    // background-color: #f00;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}