

.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-sizing: content-box;
  flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  
  // width: 1440px;
}