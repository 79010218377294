

.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  
  gap: 24px;
}

//------------------------------------------------------------------------------

.manual_rows {
  // background-color: #088;
  
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  width: 100%;
}

.input_box {
  // background: #808;
  // margin: 0;
  // flex: 1;
  // width: 100%;
}
.input_job {
  width: 100%;
}

.example_bar,
.button_bar {
  // background: #00f;
  display: flex;
  flex-direction: row;
  // padding-bottom: 10px;
  gap: 10px;
}
.button_bar {
  // background: #008;
  align-items: center;
  justify-content: space-between;
  // padding-top: 10px;
}

.button_icon {
  color: var(--green-normal);
}
//------------------------------------------------------------------------------

.frame_submit {
  // background: #0ff;
  
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.middle {
  // background: rgb(131, 146, 242);
  
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
}


.search_text {
  box-sizing: border-box;
  display: flex;
  height: 38px;
  width: 600px;
  padding: 0px 16px 0px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border: 2px solid var(--search-border-color); 
  background: var(--search-background-color); 

  &:hover {
    border: 2px solid var(--search-hover-border-color); 
    background: var(--search-hover-background-color); 
  }
}

.search_bar_btn {
  padding: 0px;
  color: rgba(255, 255, 255, 0.5) !important;
}