@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma 08 Food Selection

.row_select {
  // background: #f00;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}