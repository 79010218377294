/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2024 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@font-face {
  font-family: Audiowide;
  src: local(Audiowide-Regular),
    /* url(/assets/font/Audiowide-Regular.woff2) format("woff2"), */
    url(/assets/font/Audiowide-Regular.woff) format("woff"),
    url(/assets/font/Audiowide-Regular.ttf) format("truetype");
    /* url('https://fonts.cdnfonts.com/css/audiowide'); */
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Quantico;
  src: local(Quantico-Regular),
    /* url(/assets/font/Quantico-Regular.woff2) format("woff2"), */
    url(/assets/font/Quantico-Regular.woff) format("woff"),
    url(/assets/font/Quantico-Regular.ttf) format("truetype");
    /* url("https://db.onlinewebfonts.com/t/1a4679983e2f188cbe3b3737b5b12374.woff2")format("woff2"), */
    /* url("https://db.onlinewebfonts.com/t/1a4679983e2f188cbe3b3737b5b12374.woff")format("woff"), */
    /* url("https://db.onlinewebfonts.com/t/1a4679983e2f188cbe3b3737b5b12374.ttf")format("truetype"); */
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Quantico;
  src: local(Quantico-Bold),
    /* url(/assets/font/Quantico-Bold.woff2) format("woff2"), */
    url(/assets/font/Quantico-Bold.woff) format("woff"),
    url(/assets/font/Quantico-Bold.ttf) format("truetype");
    /* url("https://db.onlinewebfonts.com/t/4478f082ff017a0249c57dc022a524f8.woff2")format("woff2"), */
    /* url("https://db.onlinewebfonts.com/t/4478f082ff017a0249c57dc022a524f8.woff")format("woff"), */
    /* url("https://db.onlinewebfonts.com/t/4478f082ff017a0249c57dc022a524f8.ttf")format("truetype"); */
    
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}


/* -------------- Title-Audiowide -------------- */
.Title-Audiowide48px-Regular {
  font-family: Audiowide;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.Title-Audiowide38px-Regular {
  font-family: Audiowide;
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.Title-Audiowide28px-Regular {
  font-family: Audiowide;
  /* font-size: 28px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  font-size: 30px;

}

.Title-Audiowide24px-Regular {
  font-family: Audiowide;
  /* font-size: 24px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  font-size: 26px;
}

.Title-Audiowide20px-Regular {
  font-family: Audiowide;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  /* font-size: 18px; */

}

.Title-Audiowide16px-Regular {
  font-family: Audiowide;
  /* font-size: 16px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  font-size: 18px;

}

.Title-Audiowide12px-Regular {
  font-family: Audiowide;
  /* font-size: 12px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  font-size: 16px;

}

/* -------------- Contents-QuanticoBody -------------- */

.Contents-QuanticoBody20px-Bold {
  font-family: Quantico;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  
}
.Contents-QuanticoBody16px-SemiBold {
  font-family: Quantico;
  /* font-size: 16px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 18px;

}

.Contents-QuanticoBody16px-Bold {
  font-family: Quantico;
  /* font-size: 16px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 18px;

}

.Contents-QuanticoBody16px-Regular {
  font-family: Quantico;
  /* font-size: 16px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 18px;
}

.Contents-QuanticoBody16px-Regular-Btn {
  font-family: Quantico;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
}

.Contents-QuanticoBody16px-SemiBold-Btn {
  font-family: Quantico;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
}

.Contents-QuanticoBody16px-Medium {
  font-family: Quantico;
  /* font-size: 16px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 18px;

}

/* -------------- Contents-QuanticoCaption -------------- */

.Contents-QuanticoCaption12px-SemiBold {
  font-family: Quantico;
  /* font-size: 12px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 16px;

}

.Contents-QuanticoCaption12px-Bold {
  font-family: Quantico;
  /* font-size: 12px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 16px;

}



.Contents-QuanticoCaption12px-Regular {
  font-family: Quantico;
  /* font-size: 12px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 16px;

}

.Contents-QuanticoCaption12px-Medium {
  font-family: Quantico;
  /* font-size: 12px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 16px;

}

.Contents-QuanticoCaption18px-Medium {
  font-family: Quantico;
  /* font-size: 12px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 18px;

}

/* -------------- Contents-QuanticoAssistive -------------- */

.Contents-QuanticoAssistive-Text10px-SemiBold {
  font-family: Quantico;
  /* font-size: 10px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 12px;

}

.Contents-QuanticoAssistive-Text10px-Bold {
  font-family: Quantico;
  /* font-size: 10px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 12px;

}

.Contents-QuanticoAssistive-Text10px-Regular {
  font-family: Quantico;
  /* font-size: 10px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 12px;

}

.Contents-QuanticoAssistive-Text10px-Medium {
  font-family: Quantico;
  /* font-size: 10px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  font-size: 12px;

}