@use 'variables' as v;
@import './font.scss';

.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding-top: 64px;
  padding-left: 120px;
  padding-bottom: 64px;
  padding-right: 120px;
  justify-content: center;
}

.result_box {
  // background-color: #f00;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  border: 2px solid v.$grey50;
  border-color: v.$darkGreen;
  border-radius: 8px;

  width: 400px;

  .result_box_top {
    // background-color: #0f0;
    background-color: v.$darkGreen;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;

    width: 400px;
    height: 32px;

    .text_box_top {
      @include heading4-white;
    }
  }

  .result_box_bottom {
    // background-color: #00f;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: space-evenly;

    width: 400px;

    .text_box_bottom {
      @include body1-amwayBlack;
    }
  }
}
