@use '/src/assets/scss/variables'as v;
@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // box-sizing: border-box;
  gap: 32px;
}

.terms_content {
  // background-color: purple;
  @extend .Contents-QuanticoBody16px-SemiBold-White;
}

//---------------------------------------------------------------------------
.frame_button{
  // background-color: purple;
  display: flex;
  flex-direction: row;
  gap: 32px;
}