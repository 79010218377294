@use '/src/assets/scss/variables' as v;

$frame-left-width: 282px;
// 02-panel
.frame_left {
  // background: #f00;
  
  width: $frame-left-width;
  box-sizing: border-box;
  
  display: flex;
  // width: 282px;
  // padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  
  padding-right: 40px;
  position: sticky;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  
  // &.MuiTabs-indicator {}
  // &.MuiTabs-indicatorSpan {}
  
  // 01-tab
  .left_tab {
    // background: #0f0;
    
    width: 218px;
    box-sizing: border-box;
    
    display: flex;
    padding: 12px;
    // align-items: center;
    gap: 10px;
    align-self: stretch;
    
    align-items: flex-start;
    text-align: left;
    text-transform: none;
        
    &.Mui-selected {
      border-radius: 8px;
      // background: var(--neutral-grey-25-flood, #F4F4F4); 
      // background: var(--neutral-grey-25-flood, v.$grey25); 
      background-color: v.$grey25;
    }
    
    // &.Mui-focusVisible {}
  }
}


$select-background: v.$grey25;
$select-border-radius: 8px;

:export {
  selectBg: $select-background;
  // selectBg: v.$green; //debug
  selectBorderRadius: $select-border-radius;
}