// @use '/src/assets/scss/variables' as v;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

// Subpage Title
.frame {
  // background-color: #0f0;
  display: flex;
  // box-sizing: border-box;
  align-items: center;
  
  // width: Fill (1,400px)px;
  // height: Hug (45px)px;
  gap: 16px;
  height: 45px;
}