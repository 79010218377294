/* SCSS usage
@import '/src/assets/scss/pseudo';
*/

@use 'variables' as v;

@mixin link {  
  &:hover {
    cursor: pointer;
  }
}

@mixin underline {
  text-decoration: underline;
}

@mixin link-text {
  text-decoration: underline;
  text-decoration-thickness: 0.8px;
  
  &:hover {
    cursor: pointer;
  }
}

@mixin hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

@mixin hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

@mixin button-light {
  &:hover {
    cursor: pointer;
    background-color: v.$green;
    color: v.$darkGreen;
  }
}
