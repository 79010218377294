@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.frame_row {
  // background: #f00;
  
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}

.row_select {
  // background: #0f0;
  
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.select_organism {
  // background: #0ff;
  width: 400px;
  margin-right: 8px;
}

.row_button {
  margin: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.delete_button,
.add_button {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  
  height: 20px;
  width: 20px;
  
  // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
  // background: var(--Neon-Green, #0DDDCD);
  
  &:hover {
    border: 1px solid var( --green-alpha-alpha-8) !important;
    background: var( --black-black-30) !important;
  }
}

.delete_button {
  background: #FF6D6D !important;
}
