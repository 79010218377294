// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other
@import '/src/assets/scss/commonV2.scss';

// Footer
.layout {
  // background-color: #f00 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // box-sizing: border-box;

  // width: Fixed (1,440px)px;
  // height: Hug (342px)px;
  // padding: 80px 20px 40px 20px;
  gap: 32px;
  border: 1px 0px 0px 0px;
  
  // height: 342px;
  padding: 40px 120px 40px 120px;
}
//------------------------------------------------------------------------------
// Light
.frame_light {
  // background: #ff0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  
  .light {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    justify-items: center;
    width: 900px;
  }
  
  // Line 4
  .light_line4 {
    position: absolute;
    
    width: 351.72px;
    height: 0px;
    // left: 274.14px;
    gap: 0px;
    // border: 2px 0px 0px 0px;
    // height: 2px;
    
    top: 0px;
    
    // Gradient/Line 1
    // border: 2px solid;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%);
    @extend .Sidebar-Borders;
  }
  
  .light_line3 {
    position: absolute;
    
    width: 900px;
    height: 0px;
    gap: 0px;
    // border: 2px 0px 0px 0px;
    
    top: 0px;
    
    // Gradient/Line 1
    // border: 2px solid;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%);
    @extend .Sidebar-Borders;
  }
}

//---------------------------------------------------------------------------
// Frame 6
.frame_top {
  // background-color: #f00 !important;
  background-color: transparent;

  // width: Fill (1,400px)px;
  // height: Hug (50px)px;
  gap: 0px;
  justify-content: space-between;
  
  
  height: 50px;
}

.top_logo {
  width: 167.19px;
  height: 50px;
  gap: 0px;
  
}

// Footer/Link icon Groups
.top_icon {
  // width: Hug (140px)px;
  // height: Hug (32px)px;
  gap: 4px;
  
  
  width: 140px;
  height: 32px;
}

//---------------------------------------------------------------------------
// Frame 7
.frame_center {
  // background-color: #0f0 !important;
  // background-color: transparent;
  // display: flex;
  
  // width: Fill (1,400px)px;
  // height: Hug (70px)px;
  padding: 16px 0px 16px 0px;
  gap: 16px;
  
  
  height: 70px;
}

//---------------------------------------------------------------------------
// Frame 6
.frame_bottom {
  // background-color: #00f;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  // width: Fill (1,400px)px;
  // height: Hug (38px)px;
  gap: 0px;
  justify-content: space-between;
  
  width: 100%;
  height: 38px;
}

.line_5 {
  // background: #0ff !important;
  
  // width: 22px;
  // height: 0px;
  // gap: 0px;
  // border: 1px 0px 0px 0px;
  // 
  // angle: -90 deg;
  
  width: 1px;
  height: 22px;
  
  background: var(--white-white-24);
}

//---------------------------------------------------------------------------
// Button
.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  // width: Hug (123px)px;
  // height: Hug (38px)px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 2px 0px 0px 0px;
  border: 2px solid transparent;
  
  height: 38px;
  
  &:hover {
    // cursor: pointer;
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Menu-item-Borders;
  }
  &:active {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);

    @extend .Menu-item-Borders;
  }
}