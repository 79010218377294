@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;
// @import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/variablesV2';

.total_btn {
  // background: #f00;
  
  padding-top: 10px;
  padding-bottom: 20px;
  display: "flex";
  justify-content: space-between;
  align-items: center;
}

.layout_xytable {
  // title + table + title + table
  min-height: calc(32px + 806px + 32px + 891px);
}