@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.row_select {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.checkbox {
  // background: #f00;
  display: flex;
  // flex-direction: row;
}

.checkbox_text {
  // background: #0f0;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.select_class {
  width: 200px;
  margin-right: 8px;
}

.select_source {
  width: 365px;
  // margin-right: 8px;
}

.select_protein {
  width: 640px;
}

.select_block {
  display: flex;
  margin-top: 8px;
  gap: 8px;
}

//------------------------------------------------------------------------------
.btn_block {
  display: flex;
  margin-top: 16px;
  gap: 8px;
}

.btn {
  text-transform: none !important;
  color: v.$green2 !important;
  border: solid v.$green2 0.5px !important;
  margin-left: 8px;
  margin-right: 8px;
}

.btn_search {
  background-color: v.$green2 !important;
  color: white !important;
  margin-left: 8px;
  margin-right: 8px;
}