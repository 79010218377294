.totalSequence{
  background-color:#BADAFF;
  border-width:1px;
  border-color:green;
  border-style:solid;
  padding:2px 4px 2px 4px;
  margin:2px;
  line-height:30px;
}
.totalSequenceSup{
  padding-left:2px;
  padding-right:2px;
  color:blue;
}
.cuttingMark{
  color:red;
  padding:2px 4px 2px 4px;
}
