@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.layout {
  // background-color: #00f;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  gap: 20px;
}

.frame_filter {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 1200px;
  
  gap: 8px;
}

.filter_tag {
  // background: #0f0;
  
  box-sizing: content-box;
  display: flex;
  width: 1100px;
  padding: 10px 0px 10px 4px;
  gap: 4px;
  overflow-y: auto;
}

.filter_btn {
  box-sizing: border-box;
  
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  
  border-radius: 100px !important;
  // border: 1px solid var(--Dark-Green, #073431);
  border: 1px solid var(--Dark-Green, v.$darkGreen) !important;
  
  color: v.$darkGreen !important;
  background-color: v.$white !important;
  @include body2strong-darkGreen;

  &:hover {
    // background-color: v.$white;
    background-color: v.$green !important;
  }
}